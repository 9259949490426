import React from "react";
import { IconTypes } from "ui/primitives/IconTypes";
import { getThemeColor, Color } from "ui/primitives";
export default ({
  width = 24,
  height = 24,
  primaryColor = Color.text_default,
  variant,
}: IconTypes) => {
  const primaryColorValue = `var(--${primaryColor})`;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.81282 3.81282C7.45376 2.17187 9.67936 1.25 12 1.25C14.3206 1.25 16.5462 2.17187 18.1872 3.81282C19.8281 5.45376 20.75 7.67936 20.75 10C20.75 12.7123 19.6976 15.1763 18.3782 17.204C17.0574 19.2341 15.4395 20.8726 14.2343 21.9494C12.9468 23.0997 11.0532 23.0997 9.76567 21.9494C8.56046 20.8726 6.94264 19.2341 5.62177 17.204C4.30243 15.1763 3.25 12.7123 3.25 10C3.25 7.67936 4.17187 5.45376 5.81282 3.81282ZM15.2 9.99999C15.2 11.7673 13.7673 13.2 12 13.2C10.2327 13.2 8.80002 11.7673 8.80002 9.99999C8.80002 8.23268 10.2327 6.79999 12 6.79999C13.7673 6.79999 15.2 8.23268 15.2 9.99999Z"
        fill={primaryColorValue}
      />
    </svg>
  );
};
